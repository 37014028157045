// Title
$content-list-title-font: 500 #{rem-calc(24)} / rem-calc(26) $font-primary;
$content-list-title-font--large: 500  #{rem-calc(30)} / 1 $font-primary;
$content-list-title-text-align--large: left;

// Description
$content-list-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-list-description-font--large: $content-list-description-font;
$content-list-description-margin: rem-calc(0 0 10);
$content-list-description-margin--large: rem-calc(0 0 20);
$content-list-description-text-align--large: left;
$content-list-description-text-transform: none;
$content-list-description-text-transform--large: none;

// Article
$content-list-article-margin: rem-calc(0 0 15);
$content-list-article-margin--large: rem-calc(0 0 50);
$content-list-article-padding: rem-calc(0 0 45);
$content-list-article-padding--large: rem-calc(0 0 40);
$content-list-article-border-bottom: none;

// First Article
$content-list-article-first-margin: rem-calc(15 0);
$content-list-article-first-margin--large: rem-calc(20 0 50);
$content-list-article-first-padding: rem-calc(15 0 40);
$content-list-article-first-padding--large: rem-calc(10 0 40);

// Last Article
$content-list-article-last-margin--large: rem-calc(20 0 40);
$content-list-article-last-border-bottom: none;
$content-list-article-last-border-bottom--large: none;

@import "@lora/05-components/content-list";