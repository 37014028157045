$content-secondary-background: color(light);
$content-gutters--large: 0;

// Content Title
$content-title-margin: rem-calc(10 0 10 0);
$content-title-margin--large: rem-calc(0 0 25 0);
$content-title-font: 500 #{rem-calc(30)} $font-primary;
$content-title-font--large: 500 #{rem-calc(40)} $font-primary;
$content-title-max-width: rem-calc(400);

// Content Section
$content-section-padding--large: rem-calc(0 $content-gutters--large 30);
$content-section-item-margin: rem-calc(0 0 20);
$content-section-item-margin--large: rem-calc(0 (-$content-gutters--large) 20);

// Sidebar and Content
$content-sidebar-columns--large: 3 of 12;
$content-container-columns--large: 7 of 12;

// Breadcrumbs
$content-main-top-border-bottom: none;

//Content container
$content-container-margin--large: 0 auto;

//Content centered
$content-centered-secondary-background: color(light);
$content-centered-secondary-background--large: color(light);

// Check order status Section
$content-check-order-width--large: rem-calc(370);

@import "@lora/04-layout/content";