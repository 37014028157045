$search-phrases-height: rem-calc(75);
$search-no-results-phrases-height: $search-phrases-height;

// Product Results Count
$search-product-results-count-font: 500 #{rem-calc(24)} / 1.4 $font-primary;
$search-product-results-count-font--large: 500 #{rem-calc(30)} / rem-calc(16) $font-tertiary;
$search-product-results-count-text-align: left;
$search-product-results-count-position: static;
$search-results-count-inline-margin: rem-calc(0 30 0 0);

// Breadcrumbs
$search-breadcrumbs-separator: none;

// View swithcer
$search-results-view-switcher-border-right: 1px solid color(dark);

// Refinements CTA
$search-refinements-cta-border-right: 1px solid color(dark);

// Tools
$search-tools-border: 1px solid color(dark);

@import "@lora/04-layout/search";