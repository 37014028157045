
                        $is-app_ng_loyalty-enabled: true;
                        $loyalty-points-tile-label-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$loyalty-points-tile-label-font--large: null !default;
$loyalty-points-tile-label-color: color(text-secondary) !default;
$loyalty-points-tile-label-color--large: null !default;
$loyalty-points-tile-label-text-transform: null !default;
$loyalty-points-tile-label-text-transform--large: null !default;
$loyalty-points-tile-label-margin: null !default;
$loyalty-points-tile-label-margin--large: null !default;

@mixin lora-components-loyalty-points-tile {
    .c-loyalty-points-tile {
        display: flex;
        height: 100%;

        .c-content-tile__title,
        .c-content-tile__description {
            display: block;
        }
    }

    .c-loyalty-points-tile__body {
        display: flex;
        flex-flow: column;
        height: 100%;
    }

    .c-loyalty-points-tile__description {
        flex-grow: 1;
    }

    .c-loyalty-points-tile__label {
        @include text-style(
            $color: (
                small: $loyalty-points-tile-label-color,
                large: $loyalty-points-tile-label-color--large
            ),
            $font: (
                small: $loyalty-points-tile-label-font,
                large: $loyalty-points-tile-label-font--large
            ),
            $margin: (
                small: $loyalty-points-tile-label-margin,
                large: $loyalty-points-tile-label-margin--large
            ),
            $text-transform: (
                small: $loyalty-points-tile-label-text-transform,
                large: $loyalty-points-tile-label-text-transform--large
            )
        );
    }
}

                    