@import "../05-components/button";

$load-more-align-items: center !default;
$load-more-button-min-width: rem-calc(170) !default;
$load-more-button-min-width--large: null !default;
$load-more-display: flex !default;
$load-more-justify-content: center !default;
$load-more-justify-content--large: null !default;

// Load More button
$load-more-button-background: $button-background !default;
$load-more-button-hover-background: $button-hover-background !default;
$load-more-button-border: $button-border !default;
$load-more-button-hover-border: $button-border !default;
$load-more-button-color: $button-color !default;
$load-more-button-hover-color: $button-hover-color !default;

@mixin lora-components-load-more {
    .c-load-more {
        align-items: $load-more-align-items;
        display: $load-more-display;
        justify-content: $load-more-justify-content;

        @include breakpoint(large) {
            justify-content: $load-more-justify-content--large;
        }
    }

    .c-load-more__button {
        min-width: $load-more-button-min-width;

        @include lora-helper-button-style(
            $background: $load-more-button-background,
            $background-hover: $load-more-button-hover-background,
            $border: $load-more-button-border,
            $border-hover: $load-more-button-hover-border,
            $color: $load-more-button-color,
            $color-hover: $load-more-button-hover-color
        );

        @include breakpoint(large) {
            min-width: $load-more-button-min-width--large;
        }
    }
}