$stacked-links-link-font: normal #{rem-calc(12)} / 1.5 $font-primary;
$stacked-links-link-padding: rem-calc(16 0);
$stacked-links-link-padding--large: rem-calc(16 0 16 0);
$stacked-links-link-active-font: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$stacked-links-link-active-margin: rem-calc(0 0 0 10);
$stacked-links-link-active-color: color(primary);
$stacked-links-link-hover-font: 500 $stacked-links-link-font;
$stacked-links-border: 1px solid color(border);
$stacked-links-border-top: 0;
$stacked-links-item-first-child-border-top: $stacked-links-border;
$stacked-links-link-text-transform: uppercase;
$stacked-links-link-logout-color: color(secondary);
$stacked-links-link-logout-background: color(primary);
$stacked-links-link-logout-font: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$stacked-links-link-logout-text-transform: uppercase;
$stacked-links-link-logout-margin: rem-calc(20  0 0);
$stacked-links-link-letter-spacing: rem-calc(0.5);
$stacked-links-item-first-child-border-top: rem-calc(1) solid color(border);
$stacked-links-margin: rem-calc(15 25);
$stacked-links-link-logout-padding: rem-calc(16 0 16 20);
$stacked-links-link-logout-icon-margin--large: rem-calc(0 21 0 0);

@import "@lora/05-components/stacked-links";