
                        $is-app_ng_loyalty-enabled: true;
                        $loyalty-tiers-item-offset: rem-calc(20) !default; // Offset can be used for a proper tooltp vertical positioning
$loyalty-tiers-item-inactive-opacity: 0.5 !default;
$loyalty-tiers-item-inactive-filter: null !default;
$loyalty-tiers-item-image-margin: rem-calc(0 0 10) !default;

@mixin lora-components-loyalty-tiers {
    .c-loyalty-tiers {
        display: table;
    }

    .c-loyalty-tiers__items {
        width: max-content;

        @include breakpoint(large) {
            width: auto;
        }
    }

    .c-loyalty-tiers__item {
        padding-bottom: $loyalty-tiers-item-offset;
        margin-bottom: -$loyalty-tiers-item-offset;
        position: relative;
        z-index: 1; // stylelint-disable-line

        &:not(.m-passed) {
            opacity: $loyalty-tiers-item-inactive-opacity;
            filter: $loyalty-tiers-item-inactive-filter;
        }

        &.m-active {
            cursor: pointer;
        }
    }

    .c-loyalty-tiers__item-image {
        margin: $loyalty-tiers-item-image-margin;
    }
}
                    