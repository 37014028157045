$loyalty-summary-background: null !default;
$loyalty-summary-background--large: color(global-background) !default;

$loyalty-summary-count-wrapper-margin: rem-calc(0 0 20) !default;
$loyalty-summary-count-width: null !default;
$loyalty-summary-count-width--large: rem-calc(160) !default;
$loyalty-summary-count-background: color(element-background) !default;
$loyalty-summary-count-background--large: null !default;
$loyalty-summary-count-margin: rem-calc(0 -15) !default;
$loyalty-summary-count-margin--large: 0 !default;
$loyalty-summary-count-inner-padding: rem-calc(50 20) !default;
$loyalty-summary-count-inner-padding--large: rem-calc(20) !default;
$loyalty-summary-count-points-sides: rem-calc(8) !default;
$loyalty-summary-count-points-position-top: rem-calc(8) !default;
$loyalty-summary-count-points-position-top--large: null !default;
$loyalty-summary-count-points-position-left: $loyalty-summary-count-points-sides !default;

$loyalty-summary-image-padding: null !default;
$loyalty-summary-image-padding--large: rem-calc(30 20) !default;
$loyalty-summary-content-padding: null !default;
$loyalty-summary-content-padding--large: rem-calc(30 20 30 0) !default;

$loyalty-summary-title-font: #{rem-calc(18)} / 1.4 $font-primary !default;
$loyalty-summary-title-font--large: null !default;
$loyalty-summary-title-text-transform: uppercase !default;
$loyalty-summary-title-margin: rem-calc(0 0 10) !default;
$loyalty-summary-title-margin--large: 0 !default;

$loyalty-summary-description-font: #{rem-calc(14)} / 1.4 $font-primary !default;
$loyalty-summary-description-font--large: null !default;
$loyalty-summary-description-margin: rem-calc(0 0 20) !default;
$loyalty-summary-description-margin--large: null !default;

@mixin lora-components-loyalty-summary {
    .c-loyalty-summary {
        background: $loyalty-summary-background;

        @include breakpoint(large) {
            background: $loyalty-summary-background--large;
        }
    }

    .c-loyalty-summary__count-wrapper {
        margin: $loyalty-summary-count-wrapper-margin;
    }
    
    .c-loyalty-summary__count {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: $loyalty-summary-count-width;
        background: $loyalty-summary-count-background;
        margin: $loyalty-summary-count-margin;

        @include breakpoint(large) {
            width: $loyalty-summary-count-width--large;
            background: $loyalty-summary-count-background--large;
            margin: $loyalty-summary-count-margin--large;
        }
    }

    .c-loyalty-summary__count-inner {
        padding: $loyalty-summary-count-inner-padding;

        @include breakpoint(large) {
            padding: $loyalty-summary-count-inner-padding--large;
        }
    }

    .c-loyalty-summary__count-points {
        position: absolute;
        max-width: calc(100% - (#{$loyalty-summary-count-points-sides} * 2));
        top: $loyalty-summary-count-points-position-top;
        left: $loyalty-summary-count-points-position-left;

        @include breakpoint(large) {
            @include horizontal-center;

            width: max-content;
            top: $loyalty-summary-count-points-position-top--large;
        }
    }

    .c-loyalty-summary__image {
        padding: $loyalty-summary-image-padding;

        @include breakpoint(large) {
            padding: $loyalty-summary-image-padding--large;
        }
    }

    .c-loyalty-summary__content {
        padding: $loyalty-summary-content-padding;

        @include breakpoint(large) {
            padding: $loyalty-summary-content-padding--large;
        }
    }

    .c-loyalty-summary__title {
        font: $loyalty-summary-title-font;
        margin: $loyalty-summary-title-margin;
        text-transform: $loyalty-summary-title-text-transform;

        @include breakpoint(large) {
            font: $loyalty-summary-title-font--large;
            margin: $loyalty-summary-title-margin--large;
        }
    }

    .c-loyalty-summary__description {
        font: $loyalty-summary-description-font;
        margin: $loyalty-summary-description-margin;

        @include breakpoint(large) {
            font: $loyalty-summary-description-font--large;
            margin: $loyalty-summary-description-margin--large;
        }
    }
}