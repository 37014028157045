$comparison-carousel-gap: rem-calc(24) !default;
$comparison-carousel-gap--large: null !default;
$comparison-carousel-item-size: rem-calc(80) !default;
$comparison-carousel-item-size--large: null !default;
$comparison-carousel-item-padding: rem-calc(8) !default;
$comparison-carousel-item-padding--large: null !default;
$comparison-carousel-item-border: 1px solid color(border) !default;
$comparison-carousel-item-margin: rem-calc(0 -1 0 0) !default;
$comparison-carousel-item-image-transition: opacity 0.3s ease-in-out !default;

@mixin lora-components-comparison-carousel {
    .c-comparison-carousel {
        display: flex;
        flex-direction: column;
        gap: $comparison-carousel-gap;

        @include breakpoint(large) {
            gap: $comparison-carousel-gap--large;
        }
    }

    .c-comparison-carousel__item {
        position: relative;
        width: $comparison-carousel-item-size;
        height: $comparison-carousel-item-size;
        padding: $comparison-carousel-item-padding;
        border: $comparison-carousel-item-border;
        margin: $comparison-carousel-item-margin;

        @include breakpoint(large) {
            width: $comparison-carousel-item-size--large;
            height: $comparison-carousel-item-size--large;
            padding: $comparison-carousel-item-padding--large;
        }

        &.m-active {
            .c-comparison-carousel__item-image {
                opacity: 1;
            }
        }
    }

    .c-comparison-carousel__item-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition: $comparison-carousel-item-image-transition;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}