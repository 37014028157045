@import "scrollable";

$simple-navigation-background: color(element-background) !default;
$simple-navigation-color: color(text) !default;
$simple-navigation-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$simple-navigation-font--large: #{rem-calc(14)} / 1.5 $font-primary !default;
$simple-navigation-text-transform: uppercase !default;
$simple-navigation-text-decoration: none !default;
$simple-navigation-list-margin: 0 !default;
$simple-navigation-list-border-top: null !default;
$simple-navigation-list-border-bottom: null !default;
$simple-navigation-link-padding: rem-calc(14 23) !default;
$simple-navigation-link-font: null !default;
$simple-navigation-link-letter-spacing: null !default;
$simple-navigation-item-text-transform: $simple-navigation-text-transform !default;
$simple-navigation-item-border-bottom: null !default;
$simple-navigation-item-active-border-bottom: null !default;
$simple-navigation-border-bottom: none !default;
$simple-navigation-border-bottom--large: none !default;
$simple-navigation-item-align-self: center !default;
// PLP Specific Styles
$simple-navigation-subcategories-link-color: color(text) !default;
$simple-navigation-subcategories-link-padding: rem-calc(14 10) !default;
$simple-navigation-back-link-background: $simple-navigation-background !default;
$simple-navigation-back-link-border-right: 1px solid color(light) !default;
$simple-navigation-back-link-color: $simple-navigation-color !default;
$simple-navigation-back-link-font: $simple-navigation-link-font !default;
$simple-navigation-back-link-font-size: rem-calc(16) !default;
$simple-navigation-back-link-width: rem-calc(40) !default;
$simple-navigation-back-link-text-decoration: $simple-navigation-text-decoration !default;
$simple-navigation-back-link-text-transform: $simple-navigation-text-transform !default;
$simple-navigation-back-link-icon: back !default;
$simple-navigation-back-link-icon-height: rem-calc(14) !default;
$simple-navigation-back-link-icon-width: rem-calc(14) !default;
$simple-navigation-back-link-icon-usesvg: true !default;
$simple-navigation-back-link-padding: $simple-navigation-subcategories-link-padding !default;
$simple-navigation-back-link-text-padding: rem-calc(0 0 0 10) !default;
$simple-navigation-back-link-text-font: null !default;
$simple-navigation-back-link-text-letter-spacing: null !default;
// Active state
$simple-navigation-subcategories-active-link-color: color(primary-active) !default;
$simple-navigation-subcategories-active-link-font: null !default;
$simple-navigation-subcategories-active-link-letter-spacing: null !default;
$simple-navigation-item-active-padding: $simple-navigation-link-padding !default;
$simple-navigation-item-active-color: color(primary-active) !default;
$simple-navigation-item-active-font-weight: bold !default;
$simple-navigation-item-line-height: 1 !default;
// Item icon
$simple-navigation-link-icon-item-icon-max-height: rem-calc(33) !default;
$simple-navigation-link-icon-padding: rem-calc(3 23) !default;
$simple-navigation-link-icon-item-icon-padding: rem-calc(5) !default;
$simple-navigation-link-icon-top-item-icon-top: rem-calc(-14) !default;
$simple-navigation-link-icon-top-item-icon-bottom: 0 !default;
// PDP Section Styles
$simple-navigation-anchors-sticked-zindex: z(main, sectionNavStickyBar) !default;
$simple-navigation-anchors-link-color: null !default;
$simple-navigation-anchors-item-active-padding: 0 !default;
$simple-navigation-anchors-link-active-color: color(primary-active) !default;
$simple-navigation-anchors-link-active-font-weight: bold !default;
$simple-navigation-anchors-link-active-border-bottom: null !default;

@mixin lora-components-simple-navigation {
    .c-simple-navigation {
        align-items: center;
        background: $simple-navigation-background;
        border-bottom: $simple-navigation-border-bottom;
        color: $simple-navigation-color;
        display: flex;
        font: $simple-navigation-font;

        @include breakpoint(large) {
            font: $simple-navigation-font--large;
            border-bottom: $simple-navigation-border-bottom--large;
        }
    }

    .c-simple-navigation__back-link {
        cursor: pointer;
        align-items: center;
        align-self: stretch;
        background: $simple-navigation-back-link-background;
        border-#{$global-right}: $simple-navigation-back-link-border-right;
        color: $simple-navigation-back-link-color;
        display: flex;
        flex: 0 0 $simple-navigation-back-link-width;
        font: $simple-navigation-back-link-font;
        justify-content: center;
        padding: $simple-navigation-back-link-padding;
        text-decoration: $simple-navigation-back-link-text-decoration;
        text-transform: $simple-navigation-back-link-text-transform;

        &::before {
            @include svg-icon($simple-navigation-back-link-icon, $simple-navigation-back-link-color, 100%, left, no-repeat, $usesvg: $simple-navigation-back-link-icon-usesvg);

            content: '';
            display: block;
            font-size: $simple-navigation-back-link-font-size;
            height: $simple-navigation-back-link-icon-height;
            width: $simple-navigation-back-link-icon-width;
        }
    }

    .c-simple-navigation__back-link-text {
        font: $simple-navigation-back-link-text-font;
        padding: $simple-navigation-back-link-text-padding;
        letter-spacing: $simple-navigation-back-link-text-letter-spacing;
    }

    .c-simple-navigation__list-wrapper {
        @include lora-helper-scrollable-wrapper;

        width: 100%;
        overflow: hidden;
    }

    .c-simple-navigation__list {
        @include lora-helper-scrollable-items;

        list-style: none;
        margin: $simple-navigation-list-margin;
        border-top: $simple-navigation-list-border-top;
        border-bottom: $simple-navigation-list-border-bottom;
    }

    .c-simple-navigation__item {
        @include lora-helper-scrollable-item;

        line-height: $simple-navigation-item-line-height;
        text-transform: $simple-navigation-item-text-transform;
        border-bottom: $simple-navigation-item-border-bottom;
        align-self: $simple-navigation-item-align-self;
    }

    .c-simple-navigation__item.m-active {
        padding: $simple-navigation-item-active-padding;
        color: $simple-navigation-item-active-color;
        font-weight: $simple-navigation-item-active-font-weight;
        border-bottom: $simple-navigation-item-active-border-bottom;
    }

    .c-simple-navigation__link {
        cursor: pointer;
        color: $simple-navigation-color;
        display: block;
        padding: $simple-navigation-link-padding;
        text-decoration: $simple-navigation-text-decoration;
        text-transform: $simple-navigation-text-transform;
        white-space: nowrap;
        font: $simple-navigation-link-font;
        letter-spacing: $simple-navigation-link-letter-spacing;
    }

    .c-simple-navigation__list.m-subcategories {
        .c-simple-navigation__item.m-active {
            color: $simple-navigation-subcategories-active-link-color;
            padding: $simple-navigation-subcategories-link-padding;
            font: $simple-navigation-subcategories-active-link-font;
            letter-spacing: $simple-navigation-subcategories-active-link-letter-spacing;
        }

        .c-simple-navigation__link {
            color: $simple-navigation-subcategories-link-color;
            padding: $simple-navigation-subcategories-link-padding;
        }
    }

    .c-simple-navigation.m-anchors {
        .c-simple-navigation__item.m-active {
            padding: $simple-navigation-anchors-item-active-padding;

            .c-simple-navigation__link {
                color: $simple-navigation-anchors-link-active-color;
                font-weight: $simple-navigation-anchors-link-active-font-weight;
                border-bottom: $simple-navigation-anchors-link-active-border-bottom;
            }
        }

        .c-simple-navigation__link {
            color: $simple-navigation-anchors-link-color;
        }

        &.m-sticked {
            z-index: $simple-navigation-anchors-sticked-zindex;
        }
    }

    .c-simple-navigation__link.m-icon-right,
    .c-simple-navigation__link.m-icon-left {
        display: flex;
        align-items: center;
        height: 100%;
        padding: $simple-navigation-link-icon-padding;

        .c-simple-navigation__item-icon {
            max-height: $simple-navigation-link-icon-item-icon-max-height;
        }
    }

    .c-simple-navigation__link.m-icon-left {
        .c-simple-navigation__item-icon {
            padding-right: $simple-navigation-link-icon-item-icon-padding;
        }
    }

    .c-simple-navigation__link.m-icon-right {
        .c-simple-navigation__item-icon {
            order: 1;
            padding-left: $simple-navigation-link-icon-item-icon-padding;
        }
    }

    .c-simple-navigation__link.m-icon-top {
        position: relative;

        .c-simple-navigation__item-icon {
            @include horizontal-center;

            top: $simple-navigation-link-icon-top-item-icon-top;
            bottom: $simple-navigation-link-icon-top-item-icon-bottom;
        }
    }
}
